import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  styled,
} from "@mui/material";

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: "12px",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  maxWidth: "800px",
  width: "90%",
  maxHeight: "90vh",
  overflow: "auto",
}));

const MediaContainer = styled(Box)({
  marginTop: "20px",
  marginBottom: "20px",
  display: "flex",
  justifyContent: "center",
  "& img": {
    maxWidth: "100%",
    height: "auto",
    borderRadius: "8px",
  },
  "& video": {
    maxWidth: "100%",
    borderRadius: "8px",
  },
});

const DailyInfoModal = ({ show, onHide, contentData }) => {
  const [dontShowAgain, setDontShowAgain] = useState(() => {
    return localStorage.getItem(`hideDaily_${contentData?.id}`) === "true";
  });

  const handleCheckboxChange = (event) => {
    const newValue = event.target.checked;
    setDontShowAgain(newValue);
    localStorage.setItem(`hideDaily_${contentData?.id}`, newValue.toString());
  };

  const handleClose = () => {
    onHide();
  };
  const renderMedia = () => {
    if (!contentData?.media_url) return null;

    if (
      contentData.content_type === "video" ||
      contentData?.media_url?.includes("mp4")
    ) {
      return (
        <video controls>
          <source src={contentData?.presigned_url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return <img src={contentData?.presigned_url} alt={contentData.title} />;
    }
  };

  return (
    <StyledModal open={show} onClose={handleClose}>
      <ModalContent>
        <Typography variant="h4" component="h2" gutterBottom>
          {contentData?.title}
        </Typography>

        <MediaContainer>{renderMedia()}</MediaContainer>

        <Typography
          variant="body1"
          paragraph
          style={{ whiteSpace: "pre-line" }}
        >
          {contentData?.description}
        </Typography>

        <Box
          sx={{
            mt: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={dontShowAgain}
                onChange={handleCheckboxChange}
              />
            }
            label={<Typography variant="body2">Nicht mehr anzeigen</Typography>}
          />
          <Button variant="contained" onClick={handleClose}>
            Schließen
          </Button>
        </Box>
      </ModalContent>
    </StyledModal>
  );
};

export default DailyInfoModal;
