// Header.js
import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BrandingContext } from "../utils/Contexts/Context";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Box,
  styled,
  Snackbar,
  Alert,
} from "@mui/material";
import { HelpOutline, Logout } from "@mui/icons-material";
import ContentModal from "../components/Modals/ContentModal";
import { AuthContext } from "../utils/AuthProvider";
import { fetchData } from "../api/api";
import DailyInfoModal from "../components/Modals/DailyInfoModal";

// Styled components remain the same
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#fff",
  color: theme.palette.text.primary,
  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
  width: "100%",
  left: 0,
  right: 0,
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  width: "100%",
  padding: "0 24px",
  minHeight: "64px",
  "@media (max-width: 600px)": {
    padding: "0 16px",
  },
}));

const LogoWrapper = styled(Link)(({ theme }) => ({
  height: "40px",
  display: "flex",
  alignItems: "center",
  "& img": {
    height: "100%",
    objectFit: "contain",
  },
}));

const CreateButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
  padding: "8px 24px",
  fontSize: "1rem",
  borderRadius: "12px",
  textTransform: "none",
  height: "36px",
}));

const ActionsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const OuterContainer = styled(Box)(({ theme }) => ({
  width: "100vw",
  position: "relative",
  left: "50%",
  right: "50%",
  marginLeft: "-50vw",
  marginRight: "-50vw",
  backgroundColor: "#fff",
}));

const Header = () => {
  const { applicationImagePath } = useContext(BrandingContext);
  const [showModal, setShowModal] = useState(false);
  const [showDailyInfoModal, setShowDailyInfoModal] = useState(false);
  const { adminUser } = useContext(AuthContext);
  const [contentData, setContentData] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleShowModal = () => setShowModal(true);
  const handleHideModal = () => setShowModal(false);

  const handleShowDailyInfoModal = () => setShowDailyInfoModal(true);
  const handleHideDailyInfoModal = () => {
    setShowDailyInfoModal(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleHelp = () => {
    window.open("https://ernaapp.gitbook.io/ernaapp", "_blank");
  };

  const logout = () => {
    localStorage.removeItem("access_token");
    window.location.reload();
  };

  const validContentData = () => {
    if (!contentData) return false;

    const now = new Date();

    // Safely check dates
    const startDate = contentData.valid_from
      ? new Date(contentData.valid_from)
      : null;
    const endDate = contentData.valid_till
      ? new Date(contentData.valid_till)
      : null;

    // Make sure both dates exist before comparing
    if (!startDate || !endDate) return false;

    return now >= startDate && now <= endDate;
  };

  const shouldShowDailyInfo = (content) => {
    // Early return if no content is provided
    if (!content) {
      return false;
    }

    if (!(content.id > 0)) {
      return false;
    }

    // Create a temporary contentData for validation
    const tempNow = new Date();
    const tempStartDate = content.valid_from
      ? new Date(content.valid_from)
      : null;
    const tempEndDate = content.valid_till
      ? new Date(content.valid_till)
      : null;

    if (
      !tempStartDate ||
      !tempEndDate ||
      tempNow < tempStartDate ||
      tempNow > tempEndDate
    ) {
      return false;
    }

    if (adminUser) {
      return false;
    }

    const hideDaily = localStorage.getItem(`hideDaily_${content.id}`);
    return hideDaily !== "true";
  };

  useEffect(() => {
    const fetchContent = async () => {
      if (hasLoaded) return;

      try {
        const content = await fetchData("content");
        if (content && content[0]) {
          setContentData(content[0]);
          setHasLoaded(true);

          // Check if we should show the modal using the fetched content directly
          if (shouldShowDailyInfo(content[0])) {
            setShowDailyInfoModal(true);
          }
        }
      } catch (error) {
        console.error("Failed to fetch content:", error);
      }
    };

    fetchContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminUser, hasLoaded]);

  return (
    <OuterContainer>
      <StyledAppBar position="static">
        <StyledToolbar>
          <LogoWrapper to="/">
            <img src={applicationImagePath} alt="Logo" />
          </LogoWrapper>

          <Box sx={{ flexGrow: 1 }} />

          <ActionsWrapper>
            {adminUser && (
              <CreateButton
                variant="contained"
                color="primary"
                onClick={handleShowModal}
              >
                Info erstellen
              </CreateButton>
            )}
            {!adminUser && validContentData() && (
              <CreateButton
                variant="contained"
                color="primary"
                onClick={handleShowDailyInfoModal}
              >
                Tägliche Info
              </CreateButton>
            )}

            <IconButton color="primary" onClick={handleHelp} title="Hilfe">
              <HelpOutline />
            </IconButton>

            <IconButton color="primary" onClick={logout} title="Abmelden">
              <Logout />
            </IconButton>
          </ActionsWrapper>
        </StyledToolbar>
      </StyledAppBar>

      {adminUser && (
        <ContentModal
          show={showModal}
          onHide={handleHideModal}
          setSnackbar={setSnackbar}
        />
      )}
      {!adminUser && validContentData() && (
        <DailyInfoModal
          show={showDailyInfoModal}
          onHide={handleHideDailyInfoModal}
          contentData={contentData}
        />
      )}

      <Snackbar
        open={snackbar.open}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
      >
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
    </OuterContainer>
  );
};

export default Header;
