import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  FormGroup,
  FormControl,
  FormLabel,
} from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchData, deleteData, updateData, createData } from "../../api/api";

const ContentModal = ({ show, onHide, setSnackbar }) => {
  const [loading, setLoading] = useState(false);
  const [contentData, setContentData] = useState({
    id: null,
    title: "",
    description: "",
    content_type: "image",
    media: null,
    valid_from: null,
    valid_till: null,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      let response;
      if (contentData.id) {
        response = await updateData("content", contentData.id, contentData);
        setSnackbar({
          open: true,
          message: "Content updated successfully!",
          severity: "success",
        });
      } else {
        response = await createData("content", contentData, true);
        setSnackbar({
          open: true,
          message: "Content created successfully!",
          severity: "success",
        });
      }
      setContentData({
        id: response?.id,
        title: response?.title,
        description: response?.description,
        content_type: response?.content_type || "image",
        media_url: response?.media_url,
        valid_from: response?.valid_from,
        valid_till: response?.valid_till,
      });
      onHide();
    } catch (error) {
      console.error("Error creating content:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveContent = async (id) => {
    try {
      await deleteData("content", id);
      setContentData({
        id: null,
        title: "",
        description: "",
        content_type: "image",
        media: null,
        valid_from: null,
        valid_till: null,
      });
      setSnackbar({
        open: true,
        message: "Content deleted successfully!",
        severity: "success",
      });
      onHide();
    } catch (error) {
      console.error("Error deleting content:", error);
    }
  };

  const handleMediaChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setContentData({ ...contentData, media: file });
    }
  };

  const isActive = () => {
    const now = new Date();
    const startDate = contentData?.valid_from
      ? new Date(contentData.valid_from)
      : null;
    const endDate = contentData?.valid_till
      ? new Date(contentData.valid_till)
      : null;

    if (!startDate || !endDate) return false;
    return now >= startDate && now <= endDate;
  };

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const content = await fetchData("content");
        setContentData(content[0]);
      } catch (error) {
        console.error("Failed to fetch content:", error);
      }
    };

    fetchContent();
  }, []);

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            Neue Information erstellen
            <span
              style={{
                fontSize: "0.8em",
                color: isActive() ? "green" : "red",
              }}
            >
              {" "}
              ({isActive() ? "Aktiv" : "Nicht Aktiv"})
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup controlId="contentType">
            <FormLabel className="form-label">Inhaltstyp</FormLabel>
            <FormControl
              as="select"
              value={contentData?.content_type}
              onChange={(e) =>
                setContentData({ ...contentData, type: e.target.value })
              }
            >
              <option value="post">Bild</option>
              <option value="video">Video</option>
            </FormControl>
          </FormGroup>

          <FormGroup controlId="contentTitle">
            <FormLabel className="form-label">Titel</FormLabel>
            <FormControl
              type="text"
              placeholder="Titel eingeben"
              value={contentData?.title}
              onChange={(e) =>
                setContentData({ ...contentData, title: e.target.value })
              }
            />
          </FormGroup>

          <FormGroup controlId="contentDescription">
            <FormLabel className="form-label">Beschreibung</FormLabel>
            <FormControl
              as="textarea"
              rows={4}
              value={contentData?.description}
              onChange={(e) =>
                setContentData({ ...contentData, description: e.target.value })
              }
            />
          </FormGroup>

          <FormGroup controlId="contentMedia">
            <FormLabel className="form-label me-2">
              {contentData?.content_type === "video" ? "Video" : "Bild"}{" "}
              hochladen
              {contentData?.media_url && (
                <span
                  style={{
                    fontSize: "0.8em",
                    color: "grey",
                    marginLeft: "10px",
                  }}
                >
                  {"(" + contentData?.media_url + ")"}
                </span>
              )}
            </FormLabel>
            <FormControl
              type="file"
              accept={
                contentData?.content_type === "video" ? "video/*" : "image/*"
              }
              onChange={handleMediaChange}
            />
          </FormGroup>

          <FormGroup controlId="contentStartDate">
            <FormLabel className="form-label me-2">Startdatum: </FormLabel>
            <DatePicker
              selected={contentData?.valid_from}
              onChange={(date) =>
                setContentData({ ...contentData, valid_from: date })
              }
              dateFormat="yyyy-MM-dd"
              className="form-control"
              placeholderText="Startdatum auswählen"
            />
          </FormGroup>

          <FormGroup controlId="contentEndDate">
            <FormLabel className="form-label me-2">Enddatum: </FormLabel>
            <DatePicker
              selected={contentData?.valid_till}
              onChange={(date) =>
                setContentData({ ...contentData, valid_till: date })
              }
              dateFormat="yyyy-MM-dd"
              className="form-control"
              placeholderText="Enddatum auswählen"
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Abbrechen
          </Button>
          {contentData?.id && (
            <Button
              variant="danger"
              onClick={() => handleRemoveContent(contentData.id)}
            >
              Löschen
            </Button>
          )}
          <Button type="submit" variant="primary" disabled={loading}>
            {loading ? (
              <CircularProgress size={20} sx={{ color: "white" }} />
            ) : (
              "Erstellen"
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ContentModal;
