import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";

const BASE_URL = process.env.REACT_APP_API_URL;

export const login = async (credentials) => {
  try {
    const params = {
      account: {
        email: credentials.username,
        password: credentials.password,
      },
    };
    const response = await axios.post(`${BASE_URL}/account/login`, params, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Snackbar component for showing alerts
function SessionExpiredSnackbar({ open, handleClose }) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      message="Session abgelaufen. Bitte melden Sie sich erneut an."
      action={
        <Button color="secondary" size="small" onClick={handleClose}>
          OK
        </Button>
      }
    />
  );
}

const fetchCsrfToken = async () => {
  const response = await axios.get(`${BASE_URL}/csrf-token`);
  return response.data.token;
};

const acquireAccessToken = async () => {
  const response = await axios.get(`${BASE_URL}/access-token`);
  return response.data.token;
};

const loadAccessToken = async () => {
  const tokenString = localStorage.getItem("access_token");
  if (!tokenString) {
    // throw new Error("No access token found");
    window.location.reload();
  } else {
    const tokenObject = JSON.parse(tokenString);
    const userToken = tokenObject.userToken;
    if (!userToken) {
      throw new Error("No user token found in access token data");
    }
    return userToken;
  }
  return null;
};

export const getUserGroupsAuthorization = async (accessToken) => {
  const response = await axios.post(`${BASE_URL}/validateUserGroups`, {
    accessToken: accessToken,
  });
  return response.data;
};

const initializeApiClient = async (customHeaders = {}) => {
  //const csrfToken = await fetchCsrfToken();
  // const authToken = await getAuthToken();
  const apiClient = axios.create({
    baseURL: BASE_URL,
    headers: {
      Accept: "application/json",
      ...customHeaders,
    },
  });
  // apiClient.interceptors.request.use(async (config) => {
  //   try {
  //     const token = await acquireAccessToken();
  //     config.headers.Authorization = `Bearer ${token}`;
  //   } catch (error) {
  //     console.error("Error setting auth token:", error);
  //   }
  //   if (config.method !== "get") {
  //     config.headers["X-CSRF-Token"] = csrfToken;
  //   }
  //   return config;
  // });

  return apiClient;
};

export const fetchData = async (endpoint, context = "") => {
  const apiClient = await initializeApiClient();
  const token = await loadAccessToken();
  try {
    const response = await apiClient.get(`${token}/${endpoint}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const fetchEntryData = async (endpoint, param) => {
  const apiClient = await initializeApiClient();
  try {
    const response = await apiClient.get(`/${endpoint}/${param}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const createData = async (endpoint, data, isFileUpload) => {
  const apiClient = await initializeApiClient();
  const token = await loadAccessToken();
  try {
    const headers = isFileUpload
      ? { "Content-Type": "multipart/form-data" }
      : { "Content-Type": "application/json" };
    const response = await apiClient.post(`${token}/${endpoint}`, data, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error creating data:", error);
    throw error;
  }
};

export const deleteData = async (endpoint, id) => {
  const apiClient = await initializeApiClient();
  const token = await loadAccessToken();
  try {
    const response = await apiClient.delete(`${token}/${endpoint}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};

export const deleteImage = async (endpoint, id) => {
  const apiClient = await initializeApiClient();
  const token = await loadAccessToken();
  try {
    const response = await apiClient.delete(`${token}/${endpoint}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};

export const deleteMultipleData = async (endpoint, ids) => {
  const apiClient = await initializeApiClient();
  try {
    // Construct the query parameter string
    const queryString = ids.join(",");
    const response = await apiClient.delete(
      `/${endpoint}/delete?ids=${queryString}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};

export const publishData = async (endpoint, id) => {
  const apiClient = await initializeApiClient();
  const token = await loadAccessToken();
  try {
    const response = await apiClient.put(`${token}/${endpoint}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error updating data:", error);
    throw error;
  }
};

export const updateData = async (endpoint, id, data, isFileUpload = true) => {
  const apiClient = await initializeApiClient();
  const token = await loadAccessToken();
  try {
    const headers = isFileUpload
      ? { "Content-Type": "multipart/form-data" }
      : { "Content-Type": "application/json" };
    const response = await apiClient.put(`${token}/${endpoint}/${id}`, data, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating data:", error);
    throw error;
  }
};

export const updateProfile = async (endpoint, data) => {
  const apiClient = await initializeApiClient();
  const token = await loadAccessToken();
  try {
    const response = await apiClient.put(`${token}/${endpoint}`, data);
    return response.data;
  } catch (error) {
    console.error("Error updating data:", error);
    throw error;
  }
};

export const createUser = async (endpoint, data) => {
  const apiClient = await initializeApiClient();
  // const token = await loadAccessToken();
  try {
    const headers = { "Content-Type": "application/json" };
    const response = await apiClient.post(`${endpoint}`, data, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error creating data:", error);
    throw error;
  }
};

export const resetPassword = async (endpoint, data) => {
  const apiClient = await initializeApiClient();
  // const token = await loadAccessToken();
  try {
    const headers = { "Content-Type": "application/json" };
    const response = await apiClient.post(`${endpoint}`, data, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error creating data:", error);
    throw error;
  }
};

export const updatePassword = async (endpoint, data) => {
  const apiClient = await initializeApiClient();
  try {
    const headers = { "Content-Type": "application/json" };
    const response = await apiClient.put(`${endpoint}`, data, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating data:", error);
    throw error;
  }
};
