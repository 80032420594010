import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

const PRIVACY_POLICY_KEY = "privacyPolicyAccepted";
const PRIVACY_POLICY_TIMESTAMP = "privacyPolicyTimestamp";

function PrivacyPolicyModal({ show, onHide }) {
  const [rememberChoice, setRememberChoice] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [pdfLoadError, setPdfLoadError] = useState(false);

  useEffect(() => {
    // Check if device is mobile
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleAccept = () => {
    const currentTimestamp = new Date().toISOString();

    if (rememberChoice) {
      localStorage.setItem(PRIVACY_POLICY_KEY, "true");
      localStorage.setItem(PRIVACY_POLICY_TIMESTAMP, currentTimestamp);
    }

    onHide();
  };

  const handleIframeError = () => {
    setPdfLoadError(true);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Datenschutzerklärung & AGB
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {pdfLoadError && (
          <Alert variant="danger" className="mb-3">
            Der PDF-Viewer konnte nicht geladen werden. Bitte laden Sie das Dokument herunter.
          </Alert>
        )}

        {isMobile ? (
          <div>
            <Alert variant="info" className="mb-3">
              Für die beste Ansicht auf mobilen Geräten, nutzen Sie bitte eine der folgenden Optionen:
            </Alert>
            
            <div className="d-grid gap-2">
              <Button
                variant="outline-primary"
                onClick={() => window.open('/files/agb_ernaapp_vertrag.pdf', '_blank')}
              >
                PDF im Browser öffnen
              </Button>
              
              <a
                href="/files/agb_ernaapp_vertrag.pdf"
                download
                className="text-decoration-none"
              >
                <Button variant="outline-primary" className="w-100">
                  PDF herunterladen
                </Button>
              </a>
            </div>
          </div>
        ) : (
          <div>
            <div
              className="pdf-container"
              style={{
                height: "60vh",
                border: "1px solid #dee2e6",
                borderRadius: "4px",
              }}
            >
              <iframe
                src="/files/agb_ernaapp_vertrag.pdf"
                style={{ width: "100%", height: "100%", border: "none" }}
                title="Privacy Policy PDF"
                onError={handleIframeError}
              />
            </div>

            <div className="mt-3 d-flex justify-content-end">
              <a
                href="/files/agb_ernaapp_vertrag.pdf"
                download
                className="text-primary text-decoration-none"
              >
                PDF herunterladen
              </a>
            </div>
          </div>
        )}

        <Form.Check
          type="checkbox"
          id="remember-choice"
          className="mt-3"
          label="Auswahl speichern"
          checked={rememberChoice}
          onChange={(e) => setRememberChoice(e.target.checked)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleAccept} className="w-100">
          Ich habe die AGB gelesen und stimme zu
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PrivacyPolicyModal;