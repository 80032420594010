import React, { useState } from "react";
import { IconButton, TextField } from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

function UserList({
  users = [],
  toggleAdmin,
  toggleUserActivation,
  deactivateUser,
}) {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredUsers = users.filter((user) =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="user-list">
      <div className="list-heading">Benutzer</div>
      <TextField
        label="Benutzer suchen"
        variant="outlined"
        size="small"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: "1em" }}
      />
      {filteredUsers.length > 0 ? (
        <ul>
          {filteredUsers.map((user) => (
            <li
              key={user.id}
              className="user-list-item"
              style={{
                fontWeight: user.role_id == "admin" ? "bold" : "normal",
              }}
            >
              {user.email}
              <IconButton
                onClick={() => toggleAdmin(user.id)}
                title={`Erstelle ${
                  user.role_id == "admin" ? "Benutzer" : "Admin"
                }`}
              >
                <AdminPanelSettingsIcon
                  color={user.role_id == "admin" ? "primary" : "default"}
                />
              </IconButton>
              <IconButton
                onClick={() => toggleUserActivation(user.id)}
                title={`Benutzer ${
                  user.is_active ? "deaktivieren" : "aktivieren"
                }`}
              >
                {user?.verified ? (
                  <PersonIcon color="primary" />
                ) : (
                  <PersonOffIcon color="default" />
                )}
              </IconButton>
              <IconButton
                onClick={() => deactivateUser(user.id)}
                title={`Benutzer ${
                  user?.active ? "löschen" : "wiederherstellen"
                }`}
              >
                {user?.active ? (
                  <CloseIcon color="error" />
                ) : (
                  <CheckIcon color="success" />
                )}
              </IconButton>
            </li>
          ))}
        </ul>
      ) : (
        <p>Keine Benutzer gefunden.</p>
      )}
    </div>
  );
}

export default UserList;
