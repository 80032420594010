import React, { useContext } from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import SportsGymnasticsIcon from "@mui/icons-material/SportsGymnastics";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupIcon from "@mui/icons-material/Group";
import PaymentIcon from "@mui/icons-material/Payment";
import useMediaQuery from "@mui/material/useMediaQuery";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../utils/AuthProvider";
import CategoryIcon from "@mui/icons-material/Category";

export default function MobileMenu() {
  const { adminUser } = useContext(AuthContext);
  const [value, setValue] = React.useState(0);
  const isMobile = useMediaQuery("(max-width:900px)");

  if (!isMobile) {
    return null;
  }

  return (
    <Box sx={{ width: "100%", position: "fixed", bottom: 0, height: "56px" }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{
          "& .Mui-selected, .Mui-selected > svg": {
            color: "var(--main-color)",
          },
        }}
      >
        <BottomNavigationAction
          icon={<SelfImprovementIcon />}
          component={NavLink}
          to="/dashboard"
          value="/dashboard"
        />
        {adminUser && (
          <BottomNavigationAction
            icon={<GroupIcon />}
            component={NavLink}
            to="/management"
            value="/management"
          />
        )}
        <BottomNavigationAction
          icon={<AccountCircleIcon />}
          component={NavLink}
          to="/profile"
          value="/profile"
        />
        {adminUser && (
          <BottomNavigationAction
            icon={<CategoryIcon />}
            component={NavLink}
            to="/categories"
            value="/categories"
          />
        )}
        <BottomNavigationAction
          icon={<PaymentIcon />}
          component={NavLink}
          to="/preferences"
          value="/preferences"
        />
      </BottomNavigation>
    </Box>
  );
}
