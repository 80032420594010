import React, { useEffect, useState } from "react";
import CourseCard from "./CourseCard";
import "../../assets/stylesheets/CourseList.css";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { fetchData } from "../../api/api";

const CourseList = ({
  courses,
  onEdit,
  onPublish,
  onDelete,
  onSelect,
  adminStatus,
}) => {
  const [categories, setCategories] = useState([]);
  const [collapsedCategories, setCollapsedCategories] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categories = await fetchData("categories");
        setCategories(categories);
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const toggleCollapse = (categoryId) => {
    setCollapsedCategories((prevState) => ({
      ...prevState,
      [categoryId]: !prevState[categoryId],
    }));
  };

  const coursesByCategory = courses.reduce((acc, course) => {
    const { category_id, subcategory_id } = course;
    if (!acc[category_id]) acc[category_id] = {};
    if (subcategory_id) {
      if (!acc[category_id][subcategory_id])
        acc[category_id][subcategory_id] = [];
      acc[category_id][subcategory_id].push(course);
    } else {
      if (!acc[category_id].main) acc[category_id].main = [];
      acc[category_id].main.push(course);
    }
    return acc;
  }, {});

  const getCategoryDetails = (categoryId) => {
    const category = categories.find((cat) => cat.id === categoryId);
    return category
      ? category
      : { name: "Uncategorized", bg_color: "#f5f5f5", font_color: "#000" };
  };

  const getSubcategoryDetails = (categoryId, subcategoryId) => {
    const category = categories.find((cat) => cat.id === categoryId);
    const subcategory = category
      ? category.subcategories.find((subcat) => subcat.id === subcategoryId)
      : null;
    return subcategory
      ? subcategory
      : { name: "No Subcategory", bg_color: "#e0e0e0", font_color: "#000" };
  };

  if (loading) {
    return <CircularProgress />;
  }

  const sortedCategories = Object.entries(coursesByCategory).sort(
    ([a], [b]) => {
      if (a === "11") return -1;
      if (b === "11") return 1;
      const firstCourseA = courses.find(
        (course) => course.category_id === parseInt(a, 10)
      );
      const firstCourseB = courses.find(
        (course) => course.category_id === parseInt(b, 10)
      );
      return courses.indexOf(firstCourseA) - courses.indexOf(firstCourseB);
    }
  );

  return (
    <div className="course-list">
      {sortedCategories.map(([categoryId, subcategories]) => {
        const categoryDetails = getCategoryDetails(parseInt(categoryId, 10));
        return (
          <Accordion
            key={categoryId}
            expanded={!!collapsedCategories[categoryId]}
            onChange={() => toggleCollapse(categoryId)}
            sx={{ mb: 1 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${categoryId}-content`}
              id={`${categoryId}-header`}
              sx={{
                backgroundColor: categoryDetails.bg_color,
                color: categoryDetails.font_color,
                borderBottom: "1px solid #ddd",
                maxWidth: "100%",
              }}
            >
              <Typography variant="h6">{categoryDetails.name}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ overflow: "auto" }}>
              <div className="courses-container">
                {subcategories.main &&
                  subcategories.main.map((course) => (
                    <CourseCard
                      key={course.id}
                      {...course}
                      onEdit={(event) => onEdit(course.id, event)}
                      onPublish={(event) => onPublish(course.id, event)}
                      onDelete={(event) => onDelete(course.id, event)}
                      onSelect={(event) => onSelect(course.id, event)}
                      adminStatus={adminStatus}
                    />
                  ))}
              </div>
              {Object.entries(subcategories)
                .filter(([key]) => key !== "main")
                .map(([subcategoryId, subcategoryCourses]) => {
                  const subcategoryDetails = getSubcategoryDetails(
                    parseInt(categoryId, 10),
                    parseInt(subcategoryId, 10)
                  );
                  return (
                    <Accordion
                      key={subcategoryId}
                      expanded={!!collapsedCategories[subcategoryId]}
                      onChange={() => toggleCollapse(subcategoryId)}
                      sx={{ mb: 1 }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${subcategoryId}-content`}
                        id={`${subcategoryId}-header`}
                        sx={{
                          backgroundColor: subcategoryDetails.bg_color,
                          color: subcategoryDetails.font_color,
                          borderBottom: "1px solid #ddd",
                          maxWidth: "100%",
                        }}
                      >
                        <Typography variant="subtitle1">
                          {subcategoryDetails.name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ overflow: "auto" }}>
                        <div className="courses-container">
                          {subcategoryCourses.map((course) => (
                            <CourseCard
                              key={course.id}
                              {...course}
                              onEdit={(event) => onEdit(course.id, event)}
                              onPublish={(event) => onPublish(course.id, event)}
                              onDelete={(event) => onDelete(course.id, event)}
                              onSelect={(event) => onSelect(course.id, event)}
                              adminStatus={adminStatus}
                            />
                          ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default CourseList;
