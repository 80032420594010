import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  IconButton,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

// StrictModeDroppable wrapper component
const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

function CourseExerciseTabs({ exercises, setExercises }) {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const updatedExercises = exercises.map((exercise, index) => ({
      ...exercise,
      sort_order: exercise.sort_order ?? index,
    }));
    setExercises(updatedExercises);
  }, []);

  const addNewExercise = () => {
    const newExercise = {
      name: "",
      description: "",
      audio: null,
      audioPreview: null,
      sort_order: exercises.length,
    };
    setExercises([...exercises, newExercise]);
    setActiveTab(exercises.length);
  };

  const updateExercise = (index, field, value) => {
    const updatedExercises = [...exercises];
    updatedExercises[index] = {
      ...updatedExercises[index],
      [field]: value,
    };
    setExercises(updatedExercises);
  };

  const removeExercise = (index) => {
    const updatedExercises = exercises
      .filter((_, i) => i !== index)
      .map((exercise, newIndex) => ({
        ...exercise,
        sort_order: newIndex,
      }));
    setExercises(updatedExercises);
    setActiveTab(Math.min(activeTab, updatedExercises.length - 1));
  };

  const handleAudioUpload = (index, file) => {
    if (!file) return;
    const currentExercise = exercises[index];
    if (currentExercise.audioPreview) {
      URL.revokeObjectURL(currentExercise.audioPreview);
    }
    const url = URL.createObjectURL(file);
    updateExercise(index, "audio", file);
    updateExercise(index, "audioPreview", url);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(exercises);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedItems = items.map((item, index) => ({
      ...item,
      sort_order: index,
    }));

    setExercises(updatedItems);
    setActiveTab(result.destination.index);
  };

  if (!exercises.length) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <Button
          variant="contained"
          onClick={addNewExercise}
          startIcon={<AddCircleOutlineIcon />}
        >
          Erste Einheit hinzufügen
        </Button>
      </div>
    );
  }

  return (
    <div className="exercises-container">
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable
          droppableId="droppable-exercises"
          direction="horizontal"
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                display: "flex",
                borderBottom: "1px solid #ccc",
                marginBottom: "20px",
                overflowX: "auto",
                padding: "8px",
              }}
            >
              {exercises.map((exercise, index) => (
                <Draggable
                  key={`exercise-${index}`}
                  draggableId={`exercise-${index}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={{
                        ...provided.draggableProps.style,
                        userSelect: "none",
                        padding: "8px 16px",
                        margin: "0 4px",
                        background: activeTab === index ? "#fff" : "#f5f5f5",
                        border: "1px solid #ccc",
                        borderBottom:
                          activeTab === index
                            ? "1px solid white"
                            : "1px solid #ccc",
                        borderRadius: "4px 4px 0 0",
                        marginBottom: "-1px",
                        display: "flex",
                        alignItems: "center",
                        minWidth: "fit-content",
                      }}
                    >
                      <div
                        {...provided.dragHandleProps}
                        style={{ marginRight: "8px", cursor: "grab" }}
                      >
                        <DragIndicatorIcon />
                      </div>
                      <div
                        onClick={() => setActiveTab(index)}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>Einheit {index + 1}</span>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            removeExercise(index);
                          }}
                          style={{ marginLeft: "8px" }}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <IconButton
                onClick={addNewExercise}
                style={{ marginLeft: "8px" }}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>

      <div className="tab-content" style={{ padding: "20px" }}>
        {exercises.map((exercise, index) => (
          <div
            key={`content-${index}`}
            style={{ display: activeTab === index ? "block" : "none" }}
          >
            <FormControl fullWidth margin="normal">
              <FormLabel>Name</FormLabel>
              <TextField
                value={exercise.name}
                onChange={(e) => updateExercise(index, "name", e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <FormLabel>Beschreibung</FormLabel>
              <TextField
                multiline
                rows={3}
                value={exercise.description}
                onChange={(e) =>
                  updateExercise(index, "description", e.target.value)
                }
              />
            </FormControl>
            <Button
              variant="contained"
              component="label"
              style={{ marginTop: "16px" }}
            >
              Audiodatei
              <input
                type="file"
                hidden
                accept="audio/*"
                onChange={(e) => handleAudioUpload(index, e.target.files[0])}
              />
            </Button>
            {exercise.audioPreview && (
              <audio
                controls
                src={exercise.audioPreview}
                style={{ marginTop: "16px", width: "100%" }}
              >
                Your browser does not support the audio element.
              </audio>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CourseExerciseTabs;
